<template>
  <v-card class="text-center primary py-16" dark min-height="450" tile width="100%">
    <v-row align="center" justify="start" no-gutters>
      <v-col cols="4" offset="4">
        <div class="text-h3 text-center text--white my-5"><span :style="{cursor: 'pointer'}"
                                                                @click="$router.push({name: 'main'})">KFI</span></div>
      </v-col>
      <v-col cols="6" offset="3">
        <div class="text-h6 my-5">
          {{ $vuetify.lang.t('$vuetify.pages.about.title') }}
        </div>
      </v-col>
      <v-col cols="10" offset="1">
        <v-card-text class="text-start">
          {{ $vuetify.lang.t('$vuetify.pages.about.subtitle') }}
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'KurccAboutHeader'
}
</script>
